import React, { useEffect, useState } from "react";;

const Disclaimer = () => {
	return (
		<div
			className={`
			relative bottom-0 w-screen
			bg-primary bg-opacity-70 rounded-sm
      text-white text-center font-gReg text-opacity-70
		`}>
      <div className={`w-11/12 max-w-[700px] center mx-auto text-xs px-2 py-2 font-gSemi`}>
        {`Disclaimer: This is an alpha version of Trivial Pursuit Infinite, which means it's not going to be perfect - but it is still going to be wildly fun. We're working to make the experience better every single day, so enjoy being an early adopter and stay tuned for exciting updates coming soon.`}
      </div>
		</div>
	);
};

export default Disclaimer;
