import { Accessories } from 'react-peeps/lib/peeps/accessories/z_options';
import { BustPose } from 'react-peeps/lib/peeps/pose/bust/z_options';
import { Face } from 'react-peeps/lib/peeps/face/z_options';
import { FacialHair } from 'react-peeps/lib/peeps/facialHair/z_options';
import { Hair } from 'react-peeps/lib/peeps/hair/z_options';

export const BackgroundOptions = {
    'Entertainment': '#C44683',
    'Geography': '#3A98CB',
    'Sports & Leisure': '#EC6C35',
    'Science and Nature': '#8DC640',
    'History': '#FEE62A',
    'Art & Literature': '#9063B9',
    'Black' : '#000000',
    'White' : '#FFFFFF'
}
export const SkinTones = {
    1: '#FFFFFF',
    2: '#FDE7D6',
    3: '#F3D1A2',
    4: '#E4B678',
    5: '#C49775',
    6: '#A76A39',
    7: '#8C4B31',
    8: '#653227',
    9: '#C88BFF',
    10: '#9FAE97',
    11: '#78B6FF',
    12: '#FF69B4'
}

export const CustomizeOptions = [
	"Head",
	"Face",
	"Facial Hair",
	"Body",
	"Glasses",
	"Background",
	"Skin"
]
export const CustomizeStep = {
	USERNAME: 'username',
	CUSTOMIZE: 'customize',
	FINALIZE: 'finalize',
	DISCLAIMER: 'disclaimer',
}

export const randomizeAvatar = (avatarState, setAvatarState) => {
    setAvatarState({
        accessory: Object.keys(Accessories)[Math.floor(Math.random() * Object.keys(Accessories).length)],
        body: Object.keys(BustPose)[Math.floor(Math.random() * Object.keys(BustPose).length)],
        face: Object.keys(Face)[Math.floor(Math.random() * Object.keys(Face).length)],
        facialHair: Object.keys(FacialHair)[Math.floor(Math.random() * Object.keys(FacialHair).length)],
        hair: Object.keys(Hair)[Math.floor(Math.random() * Object.keys(Hair).length)],
        backgroundColor: SkinTones[Object.keys(SkinTones)[Math.floor(Math.random() * Object.keys(SkinTones).length)]],
        circleStyle: {
            ...avatarState.circleStyle,
            backgroundColor: BackgroundOptions[Object.keys(BackgroundOptions)[Math.floor(Math.random() * Object.keys(BackgroundOptions).length)]]
        }
    })
}

export const getRandomAvatar = () => {
    return {
        accessory: Object.keys(Accessories)[Math.floor(Math.random() * Object.keys(Accessories).length)],
        body: Object.keys(BustPose)[Math.floor(Math.random() * Object.keys(BustPose).length)],
        face: Object.keys(Face)[Math.floor(Math.random() * Object.keys(Face).length)],
        facialHair: Object.keys(FacialHair)[Math.floor(Math.random() * Object.keys(FacialHair).length)],
        hair: Object.keys(Hair)[Math.floor(Math.random() * Object.keys(Hair).length)],
        backgroundColor: SkinTones[Object.keys(SkinTones)[Math.floor(Math.random() * Object.keys(SkinTones).length)]],
        circleStyle: {
            width: 150,
			height: 150,
			alignSelf: 'center',
			borderRadius: 135,
			overflow: 'hidden',
			borderWidth: 3,
			borderColor: 'black',
			borderStyle: 'solid',
            backgroundColor: BackgroundOptions[Object.keys(BackgroundOptions)[Math.floor(Math.random() * Object.keys(BackgroundOptions).length)]]
        }
    }
}

export const AccessoryViewBox = {
    x: '250', y: '150', width: '500', height: '400'
}

export const BodyViewBox = {
    x: '-50', y: '400', width: '1000', height: '800'
}

export const FaceViewBox = { 
    x: '315', y: '150', width: '500', height: '400' 
}

export const FacialHairViewBox = { 
    x: '250', y: '200', width: '500', height: '400' 
}

export const HairViewBox = { 
    x: '-50', y: '-150', width: '1000', height: '800' 
}

export const CardBackground = 'w-30 h-30 bg-white bg-opacity-50 rounded-sm m-2 shadow-lg'
export const CardBackgroundSelected = 'w-30 h-30 bg-staff bg-opacity-25 border-2 border-staff rounded-md m-2 shadow-lg'
