import { useQuery } from "@tanstack/react-query";
import { usePlayer } from "@/lib/usePlayer";

const fetchTrending = async (uid) => {
  uid = uid.queryKey[1]
  let api = `/api/playlists?type=trending&uid=${uid}`;
  const res = await fetch(api)
  const trending = await res.json();
  return trending;
};

const fetchStaffPicks = async (uid) => {
  uid = uid.queryKey[1]
  let api = `/api/playlists?type=staff_pick&uid=${uid}`;
  const res = await fetch(api)
  const staffPicks = await res.json()
  return staffPicks;
};

const useTrending = () => {
  const { data: player } = usePlayer();
  const uid = player?.uid  
  const trending = useQuery({
    queryKey: ['fetchTrending', uid],
    queryFn: async (uid) => await fetchTrending(uid),
    enabled: !!uid,
  })
  return trending;
}

const useStaffPicks = () => {
  const { data: player } = usePlayer();
  const uid = player?.uid
  const staffPicks = useQuery({
    queryKey: ['staffPicks', uid],
    queryFn: async (uid) => await fetchStaffPicks(uid),
    enabled: !!uid,
  })
  return staffPicks;
}

export { useTrending, useStaffPicks };
