import React from 'react';
import Panel from "@/components/panels/Panel";
import PanelSpinner from "@/components/panels/PanelSpinner";
import PanelButton, {TYPE_CANCEL} from "@/components/panels/PanelButton";
import Image from "next/image";
import Countdown from "@/components/panels/Countdown";
import SmallLoader from "@/components/common/SmallLoader";
import { sendGtagEvent } from "@/lib/utils";
import cx from 'classnames';
import router from "next/router";
import { useStaffPicks } from "@/lib/usePublicPlaylists";
import { usePlayer } from "@/lib/usePlayer";
import { useChangePlayerGame } from "@/lib/usePlayerGame";

/**
 * @param topic
 * @param onSelect
 * @returns {JSX.Element}
 * @constructor
 */
const PickItem = ({topic, onSelect, played, inProgress}) => {
	return <button className={cx('text-staff px-4 py-1 m-0.5 xs:m-0 font-gBold rounded-full cursor-pointer hover:bg-yellow-200	', { 'bg-slate-300': played, 'bg-amber-100': inProgress, 'bg-white': !played && !inProgress })} onClick={onSelect} data-testid={`button-pickItem`} aria-label={`button-pickItem`} >
		<div className={'truncate xs:max-w-[420px] max-w-[280px] capitalize'}>{topic}</div>
	</button>
}

/**
 * present Staff picks. functionally identical to the TrendingPanel - without the show more/less modality
 *
 * @param items (array) a list of items to display. each item should have a `topic` and an `_id`
 * @param nextItem (object) the next item to be played if/when autoplay triggers
 * @param onItemSelect (function) called when user selects an item. the selected item object is passed as a single function arg
 * @param autoplay (boolean) when true, a 10 second countdown appears.
 * @param onAutoplay (function) called when countdown timer reaches 0
 * @param onCancelAutoplay (function) called when a user cancels a autoplay countdown
 * @returns {JSX.Element}
 * @constructor
 */
const StaffPanel = ({nextItem, autoplay=false, onAutoplay=()=>null, onCancelAutoplay=()=>null, className=''}) => {	
	const { data: player } = usePlayer();
	const { data: staffPicks } = useStaffPicks();

	const { mutate } = useChangePlayerGame();

	/* c8 ignore next 10 */
	const sendEvent = () => {
		sendGtagEvent({
			action: 'staff_play_click',
			category: 'engagement',
			label: 'Staff Play Click',
			value: 'click',
			user_id: player?.uid
		});
	}

	/* c8 ignore next 7 */
	const onItemSelect = async (item, uid) => {
		const { shareId } = item;
		mutate({uid, body: {shareId, source: 'staff_panel'}})
		sendEvent()
		router.push(`/staff_pick?shareId=${shareId}`)
	}

	const items = staffPicks?.map((tpl, i) => {
		return { _id: tpl._id, shareId: tpl.shareId, topic: tpl.playlist.topic, rank: i + 1, played: tpl.played, inProgress: tpl.inProgress };
	})

	return (
		<Panel
			helpHref={'/help/staff-picks'}
			backgroundImage={'bg-staff-panel'}
			midgroundImage={<Image src={'/panels/staff-mg.svg'} alt={'Staff Picks icon'} fill={true} />}
			helpTestId={"help-staff-picks"}
			grow={true}
			className={className}
		>
			<div className={'min-h-[290px] flex flex-col items-center justify-center gap-2'}>
				{(items?.length < 1) ? 
          <PanelSpinner />
          : null
        }
		{/* c8 ignore next 15 */}
				{autoplay ?
					<>
						<div className={'flex flex-col items-center justify-center'}>
							<h2 className={'text-sm font-gBold'}>Staff Picks</h2>
							<h3 className={'text-2xl font-gBold'}>Next Round:</h3>
						</div>
						{nextItem ? <PickItem {...nextItem} onSelect={() => {
							sendEvent(); // Will probably need to be updated afer auto play is working...
							onItemSelect(nextItem, player?.uid);
						}} /> : <SmallLoader />}
						<p className={'text-center px-8 text-2xl pb-2 font-gReg'}>Beginning in <Countdown onComplete={onAutoplay} />...</p>
						<PanelButton type={TYPE_CANCEL} textColor={'text-staff'} onClick={onCancelAutoplay} />
					</> :
					<>
						<div className={'flex flex-col items-center justify-center'}>
							<h2 className={'text-2xl font-gBold'}>Staff Picks</h2>
						</div>
						<p className={'text-center leading-5 px-8 text-base pb-2 font-gReg w-98 max-w-[370px]'}>Weird, wild, and wonderful trivia playlists curated by the Trivial Pursuit Infinite team!</p>
						<div className={'flex flex-wrap items-center justify-center gap-2'}>
							{/* c8 ignore next 3 */}
							{items ? items.map((p, i) => <PickItem key={i} {...p} onSelect={() => {
								sendEvent();
								onItemSelect(p, player?.uid);
							}} />) : <SmallLoader />}
						</div>
					</>
				}
			</div>
		</Panel>
	);
};

export default StaffPanel;
