import React from 'react';

const PanelGrid = ({className='', children}) => {
	return (
		<div className={`
			grid grid-cols-1 xl:grid-cols-10 xl:gap-2 grid-flow-row-dense		
			justify-center rounded-lg max-w-[1200px] xl:max-w-[1350px]
			mx-auto my-4 xl:my-auto w-fit h-full
			${className}
		`}>
			{children}
		</div>
	);
};

export default PanelGrid;
