import React, {useEffect, useRef, useState} from "react";
import { GAME_MODES } from "@/lib/constants.mjs";
import LoadingPies from "./loader.svg";
import { getRandomLoadingMsg } from "@/lib/utils";
import QuitButton from "@/components/common/buttons/QuitButton";

const Loading = ({ mode = GAME_MODES.daily, onCancel=() => null }) => {
  const [message, setMessage] = useState();
  const [showMessage, setShowMessage] = useState(false);
  const [showCancelButton, setShowCancelButton] = useState(false);
	const messageTimer = useRef()
	const cancelTimer = useRef()

  useEffect(() => {
    setMessage(getRandomLoadingMsg());

    // only show a message after 3 seconds to avoid message flash
    messageTimer.current = setTimeout(() => {
      setShowMessage(true);
    }, 3000);

		// after 60 seconds, show a cancel option
		cancelTimer.current = setTimeout(() => {
			setShowCancelButton(true);
		}, 60000)

		// cleanup
		return () => {
			clearTimeout(messageTimer.current)
			clearTimeout(cancelTimer.current)
		}
  }, []);

  const color =
    mode === GAME_MODES.endless ? "bg-darkness bg-opacity-80" : "bg-secondary bg-opacity-35";
  return (
    <div
      className={
        "flex flex-col gap-4 items-center justify-center flex-grow w-full xl:max-w-[1040px] lg:max-w-[960px] max-w-[650px] p-4"
      }
    >
      <div className={`flex-col items-center justify-center`}>
        <div className={`p-4 rounded-full backdrop-blur ${color}`}>
          <LoadingPies
            data-testid="loading-pies"
            color={"#fff"}
            width={72}
            height={72}
            aria-label="Loading"
          />
        </div>
      </div>
      {mode === GAME_MODES.endless ? (
        <div
          className={`flex flex-col text-primary bg-white bg-opacity-40 rounded-lg p-4 drop-shadow-lg transition-opacity duration-300 ${
            !showMessage && "opacity-0"
          }`}
        >
          <h1 className={"font-gBold lg:text-2xl text-xl text-center"}>{message}</h1>
        </div>
      ) : null}
			<QuitButton label={'Cancel'} onClick={onCancel} dataTestId={'loading-cancel'} className={`${showCancelButton ? '' : 'opacity-0 pointer-events-none cursor-default'}`} />
    </div>
  );
};

export default Loading;
