import React from "react";

const QuitButton = ({
  label = "Quit",
  onClick,
  dataTestId = "quit",
  className = "",
}) => {
  return (
    <button
      className={`hover:bg-white hover:bg-opacity-30 bg-transparent hover:text-primary text-white text-xl font-gSemi p-2 px-6 uppercase rounded-lg w-full max-w-[650px] ${className}`}
      onClick={onClick}
      data-testid={dataTestId}
    >
      {label}
    </button>
  );
};

export default QuitButton;
