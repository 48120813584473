import React, { useState } from "react";
import Panel from "@/components/panels/Panel";
import PanelSpinner from "@/components/panels/PanelSpinner";
import PanelButton, { TYPE_CANCEL } from "@/components/panels/PanelButton";
import Image from "next/image";
import Countdown from "@/components/panels/Countdown";
import SmallLoader from "@/components/common/SmallLoader";
import Fire from "@/components/icons/fire.svg";
import { sendGtagEvent } from "@/lib/utils";
import cx from 'classnames';
import router from "next/router";
import { useTrending } from "@/lib/usePublicPlaylists";
import { usePlayer } from "@/lib/usePlayer";
import { useChangePlayerGame } from "@/lib/usePlayerGame";

/**
 * @param rank
 * @param topic
 * @param onSelect
 * @returns {JSX.Element}
 * @constructor
 */
const TrendingItem = ({ rank, topic, onSelect, played, inProgress }) => {
  return (
    <button
      about={topic}
      className={
        cx("flex items-center gap-2 text-trending pl-1 pr-4 py-1 m-0.5 xs:m-0 font-gBold rounded-full cursor-pointer max-w-full hover:bg-yellow-200", { 'bg-slate-300': played, 'bg-amber-100': inProgress, 'bg-white': !played && !inProgress })
      }
      onClick={onSelect}
      data-testid={`button-trendingItem`}
      aria-label={`button-pickItem`}
    >
      <div
        className={
          "bg-trending text-white min-w-[24px] max-w-min min-h-[24px] max-h-min rounded-full"
        }
      >
        {rank}
      </div>
      <Fire />
      <div className={"truncate xs:max-w-[420px] max-w-[240px] capitalize"}>{topic}</div>
    </button>
  );
};

/**
 * present trending topics. has autoplay functionality and a show more/less toggle to expand/collapse the list of playlists
 *
 * @param items (array) a list of items to display. each item should have `rank`, `topic` and `_id` fields
 * @param nextItem (object) the next item to be played if/when autoplay triggers
 * @param onItemSelect (function) called when user selects an item. the selected item object is passed as a single function arg
 * @param autoplay (boolean) when true, a 10 second countdown appears.
 * @param onAutoplay (function) called when countdown timer reaches 0
 * @param onCancelAutoplay (function) called when a user cancels a autoplay countdown
 * @returns {JSX.Element}
 * @constructor
 */
const TrendingPanel = ({
  nextItem,
  autoplay = false,
  onAutoplay = () => null,
  onCancelAutoplay = () => null,
}) => {
	const { data: player } = usePlayer();
	const { data: trending } = useTrending();

	const { mutate } = useChangePlayerGame();

  /* c8 ignore next 10 */
  const sendEvent = () => {
    sendGtagEvent({
      action: "trending_play_click",
      category: "engagement",
      label: "Trending Play Click",
      value: "click",
      user_id: player?.uid,
    });
  };

  /* c8 ignore next 7 */
	const onItemSelect = async (item, uid) => {
		const { shareId } = item;
		mutate({uid, body: {shareId, source: 'trending_panel'}})
		sendEvent()
		router.push(`/trending?shareId=${shareId}`)
	}

	const items = trending?.map((tpl, i) => {
    return { _id: tpl._id, shareId: tpl.shareId, topic: tpl.playlist.topic, rank: i + 1, played: tpl.played, inProgress: tpl.inProgress };
  })

  const [expanded, setExpanded] = useState(false);
  const itemsToShow = expanded ? items : items?.slice(0, 3);
  return (
    <Panel
      helpHref={"/help/trending"}
      backgroundImage={"bg-trending-panel"}
      midgroundImage={
        <Image
          src={"/panels/trending-mg.svg"}
          alt={"Trending Playlists icon"}
          fill={true}
        />
      }
      helpTestId={"help-trending"}
      grow={true}
    >
      <div className={"min-h-[232px] flex flex-col items-center justify-center gap-2"}>
        {(items?.length < 1) ? 
          <PanelSpinner />
          : null
        }
        {/* c8 ignore next 30 */}
        {autoplay ? (
          <>
            <div className={"flex flex-col items-center justify-center"}>
              <h2 className={"text-sm font-gBold"}>
                Trending Playlists
              </h2>
              <h3 className={"text-2xl font-gBold"}>Next Round:</h3>
            </div>
            {nextItem ? (
              <TrendingItem
                {...nextItem}
                onSelect={() => {
                  sendEvent();
                  onItemSelect(nextItem, player?.uid)
                }}
              />
            ) : (
              <SmallLoader />
            )}
            <p className={"text-center px-8 text-2xl pb-2 font-gReg"}>
              Beginning in <Countdown onComplete={onAutoplay} />
              ...
            </p>
            <PanelButton
              type={TYPE_CANCEL}
              textColor={"text-trending"}
              onClick={onCancelAutoplay}
            />
          </>
        ) : (
          <>
            <div className={"flex flex-col items-center justify-center"}>
              <h2 className={"text-2xl font-gBold"}>
                Trending Playlists
              </h2>
            </div>
            <p className={"text-center px-8 text-base pb-2 font-gReg"}>
              Freshly baked trivia from our community:
            </p>
            <div className={"flex flex-col items-start gap-2"}>
              {items ? (
                itemsToShow.map((p, i) => (
                  <TrendingItem
                    key={i}
                    {...p}
                    onSelect={() => {
                      sendEvent();
                      onItemSelect(p, player?.uid)
                    }}
                  />
                ))
              ) : (
                <SmallLoader />
              )}
            </div>
            {items && (
              <button
                data-testid={`button-seeTopics`}
                className={
                  "uppercase font-gBold bg-white bg-opacity-30 px-4 py-1 rounded-full"
                }
                onClick={() => setExpanded(!expanded)}
              >
                See {expanded ? "less" : "more"} topics
              </button>
            )}
          </>
        )}
      </div>
    </Panel>
  );
};

export default TrendingPanel;
