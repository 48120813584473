import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { STORAGE_KEY } from "@/lib/utils";

const fetchPlayer = async () => {
  let api = `/api/user`;
  let uid = localStorage.getItem(STORAGE_KEY);

  if (!uid) {
    const res = await fetch(api);
    const player = await res.json();
    localStorage.setItem(STORAGE_KEY, player.uid);
    return player;
  }
  else if (!!uid) {
    api += `/${uid}`;
    const res = await fetch(api);
    if (res.status === 404) {
      localStorage.removeItem(STORAGE_KEY);
      return await fetchPlayer();
    }
    const player = await res.json();
    return player;
  }
};

const usePlayer = () => {
  const player = useQuery({
    queryKey: ["player"],
    queryFn: async () => await fetchPlayer(),
    staleTime: Infinity, 
    cacheTime: 0
  });
  return player;
};

const useUpdateDaily = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({uid, body}) => {

      const res = await fetch(`/api/user/${uid}/daily`, {
        method: 'POST',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const data = await res.json();
      return data;
    },
    onSuccess: (data) => {
      queryClient.setQueryData(['player'], data);
    },
    onError: (_err, _variables, ctx) => {
      // if (!hookOptions.useOptimisticUpdates) return;
      ctx?.forEach((snapshot) => {
        queryClient.setQueryData(snapshot.queryKey, snapshot.previousData);
      });
    },
  });
}

export { usePlayer, useUpdateDaily };
