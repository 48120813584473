import React, { useRef } from 'react';
import router from "next/router";
import Panel from "@/components/panels/Panel";
import ButtonGroup from "../common/buttons/ButtonGroup";
import Leaderboards from '@/components/live/Leaderboards'
import LeaderboardModal from '@/components/live/LeaderboardModal'
import PanelButton from '@/components/panels/PanelButton';

const LiveModePanel = ({className=''}) => {	
	const leaderboardRef = useRef(null);

	const onOpenLeaderboard = () => {
		leaderboardRef.current.click();
	}

	const onPlay = () => {
		router.push('/live');
	}

	return (<>
		<Panel
			className={`${className} bg-gradient-to-b from-[#50197D] to-[#000000] via-[#3B125C] via-60%`}
			helpHref={'/help/live'}
			helpTestId={"help-live"}
			grow={true}
		>
			<div className={'min-h-[250px] flex flex-col items-center justify-center gap-2'}>
				<div className={'flex flex-col items-center justify-center'}>
					<h2 className={'text-2xl font-gBold'}>Trivial Pursuit Live (New!)</h2>
					<p className={'text-center px-8 text-sm py-1'}>
						Fresh trivia, served up 24/7. Compete live to claim your spot on the leaderboards!
					</p>				
				</div>
				<Leaderboards limit={true} />
				<ButtonGroup
					primaryLabel={"Leaderboards"} 
					primaryAction={onOpenLeaderboard} 
					primaryEnabled={true} 
					primaryDataTestId={"Leaderboards"}
					secondaryLabel={'Play'}
					secondaryAction={onPlay} 
					secondaryDataTestId={'Play'}
					className={`!max-w-[400px]`}
					style="live"
				/>
			</div>
		</Panel>
		<LeaderboardModal ref={leaderboardRef} />
	</>
)};

export default LiveModePanel;
