import React from "react";
import Link from "next/link";
import HelpSVG from "@/components/icons/help-button.svg"

const InfoPanel = ({className=""}) => {
	return (
		<div
			className={`
			z-auto relative p-2 rounded-md w-full max-w-[480px] min-h-[50px] 
			flex items-center justify-end 
			bg-primary bg-opacity-75 bg-contain
      text-white text-center font-gSemi
			overflow-hidden
			${className}
		`}>
      <div className={`text-base max-w-[300px] my-auto mr-auto ml-auto pl-2 tracking-wider font-gSemi`}>
        {`Learn about how we're building the future of trivia — with your help!`}
      </div>
			<div className={'self-baseline'}>
				<Link href={'/about'} data-testid={"help-button"} aria-label="About">
					<div className={'relativetop-0 right-0 w-[32px] h-[32px] hover:opacity-50 z-10'}><HelpSVG /></div>
				</Link>
			</div>
		</div>
	);
};

export default InfoPanel;
