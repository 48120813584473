import { useMutation, useQueryClient } from "@tanstack/react-query";

const useUpdatePlayerGame = (pursuit) => {
  const queryClient = useQueryClient();

  const defaultHeaders = {
		Accept: "application/json",
		"Content-Type": "application/json",
	}

  return useMutation({
    mutationFn: async ({uid, body, headers=defaultHeaders}) => {
			const res = await fetch(`/api/user/${uid}/infinite`, {
				method: 'POST',
				headers,
				body: JSON.stringify(body),
			})
			const data = await res.json()
			return data
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries()
      // queryClient.setQueryData(['player'], data);
    },
    onError: (_err, _variables, ctx) => {
      ctx?.forEach((snapshot) => {
        queryClient.setQueryData(snapshot.queryKey, snapshot.previousData);
      });
    },
  });
}

const useChangePlayerGame = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({uid, body}) => {
      const res = await fetch(`/api/user/${uid}/playerGame`, {
        method: 'POST',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const data = await res.json();
      return data;
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries()
      // queryClient.setQueryData(['player'], data);
    },
    onError: (_err, _variables, ctx) => {
      ctx?.forEach((snapshot) => {
        queryClient.setQueryData(snapshot.queryKey, snapshot.previousData);
      });
    },
  });
}

export { useUpdatePlayerGame, useChangePlayerGame };
