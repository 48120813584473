import React, {useEffect, useRef, useState} from 'react';

const Countdown = ({onComplete, className='', length=10}) => {
	const [seconds, setSeconds] = useState(length)
	const t = useRef(length)
	const interval = useRef()

	/* c8 ignore next 3 */
	const stopTimer = () => {
		clearInterval(interval.current)
	}

	/* c8 ignore next 20 */
	useEffect(() => {
		if (t.current > 0) {
			interval.current = setInterval(() => {
				t.current -= 1
				setSeconds(t.current);
				if (t.current <= 0) {
					stopTimer();
					onComplete()
				}
			}, 1000)
		} else if (interval.current) {
			clearInterval(interval.current)
		}

		return () => {
			if (interval.current) clearInterval(interval.current)
		}

	}, [onComplete]);

	return (<>{seconds}</>);
};

export default Countdown;
