import React, {useRef} from 'react';
import Panel from "@/components/panels/Panel";
import PanelButton, {TYPE_SHOP} from "@/components/panels/PanelButton";
import RestrictedLink from "@/components/common/RestrictedLink";
import {getUtmProductUrl, sendGtagEvent, STORAGE_KEY} from "@/lib/utils";

/**
 * present a "Shop Now" button that brings the player to the amazon page
 *
 * @returns {JSX.Element}
 * @constructor
 */
const ShopPanel = ({className=''}) => {
	const linkRef = useRef(null);

	/* c8 ignore next 12 */
	const openShopNowUrl = () => {
		const uid = localStorage.getItem(STORAGE_KEY);

		sendGtagEvent({
			action: `panel_shop_now_click`,
			category: 'engagement',
			label: `Panel Shop Now Click`,
			value: 'click',
			user_id: uid
		});
		linkRef.current.click();
	}

	return (
		<Panel backgroundImage={`bg-shop-panel ${className}`} grow={true}>
			<div className={'flex flex-col items-center justify-center gap-2'}>
				<div className={'flex flex-col items-center justify-center'}>
					<h2 className={'text-2xl font-gBold'}>Show off your knowledge!</h2>
				</div>
				<p className={'text-center px-8 leading-5 text-base pb-2 font-gReg w-96'}>Shop Trivial Pursuit games from Classic Edition to Harry Potter Edition & More!</p>
				<PanelButton type={TYPE_SHOP} onClick={openShopNowUrl} />
			</div>
			<RestrictedLink ref={linkRef} href={getUtmProductUrl('panel_shop_now_click')} />
		</Panel>
	);
};

export default ShopPanel;
